import * as React from "react"
import MainLayout from "../components/Layout/MainLayout"
import Section from "../components/Section"
import classnames from "../helpers/classnames"
import Logo from "../components/logo/logo"
import List from "../components/list"
import Footer from "../components/footer"
import Button from "../components/button"
import { validateEmail } from "../lib/validators"
import { useState } from "react"
import { mailchimpSubscribe } from "../lib/mailchimApi"
import FlyingShape from "../components/FlyingShape"
import styles from "../styles/page-cyber-monday-game.module.scss"

const Page = ({ ...rest }) => {
  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)

  const isFormValid = () => {
    return validateEmail(email)
  }

  const handleFormSubmit = event => {
    event.preventDefault()

    mailchimpSubscribe({
      email,
      audience: "main",
      tags: ["subscribed for Cyber Monday updates"]
    })
      .then(_ => {
        setMessage("Great! Don’t forget to watch for emails and updates from us during Cyber Monday.")
      })
      .catch(_ => {
        setMessage("There was an error. Please try again.")
      })
  }

  return (
    <MainLayout>

      <div className={classnames("text-center my-32")}>
        <a href="/#" title="MINDWORX" className="logo">
          <Logo style={{ width: "90px", height: "90px" }}/>
        </a>
      </div>

      <Section
        className={classnames("py-0")}
        title={<>Cyber Monday Game</>}
      >

        <div style={{ position: "relative" }}>
          <FlyingShape
            top={220}
            right={-140}
            offsetX={0}
            screens={{ xs: 0, sm: 0, 32: 1, lg: 1 }}
            factorX={{ md: 0.26, lg: 0.26 }}
            color="#0017be"
          />

          <FlyingShape
            top={920}
            left={0}
            offsetX={-200}
            screens={{ xs: 0, sm: 0, 32: 1, lg: 1 }}
            factorX={{ md: -0.1, lg: -0.1 }}
            shape="triangle"
            color="#EBF807"
          />
        </div>
        <div className={styles.content}>
          <p>Do you prefer a safe bet or an adventure? Here are the rules of the Cyber Monday game:</p>

          <List className={classnames("mb-40")} type="number">
            <span>Black Friday (Friday 27th) - <strong>fixed 60% off</strong></span>
            <span>Cyber Monday (Monday 30th) - <strong>fixed 40% off + extra reward on top</strong></span>
          </List>

          <p>
            We’ll unlock the extra rewards at different times during Cyber Monday. We’ll publish them in our <strong>Instagram
            stories</strong>, on the <strong><a className="underlined underlined:href" href="/">course website</a></strong>, and we’ll send them by <strong>email</strong>.
          </p>

          <p>What can you win?</p>
          <List type="circle">
            <span>Extra discounts up to 75%,</span>
            <span>Signed (book) Alchemy by Rory Sutherland,</span>
            <span>Free consulting sessions,</span>
            <span>And more</span>
          </List>

          <div className={"text-center"}>

            <div className="my-80 sm:my-64">
              <div>
                <div>
                  So, not to miss our special Cyber Monday rewards, you can:
                </div>
                <div>
                  <Button
                    href="https://www.instagram.com/mindworx_academy/"
                    target="blank"
                    width={230}
                  >FOLLOW ON INSTAGRAM</Button>
                </div>
              </div>

              <div className={"my-32 sm:my-32"}>
                or
              </div>

              <div>
                <div>
                  <strong>sign-up to receive the rewards by email:</strong>
                </div>
                <form
                  method="POST"
                  onSubmit={handleFormSubmit}
                >
                  <input
                    className={classnames(
                      "affiliate-form__input",
                      "my-16 mx-auto"
                    )}
                    style={{ maxWidth: "230px", height: "48px" }}
                    name="email"
                    required={true}
                    type="email"
                    placeholder="Your email"
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Button width={230} className={"mt-0"}>
                    SIGN-UP
                  </Button>
                  <div className={"mt-8"}>{message}</div>
                </form>
              </div>
            </div>

            <p className={"mb-80"}>
              You'll never know what deal is coming so make sure to watch out for the one that suits you best.
            </p>

            <h2 className="text-center">Good luck!</h2>

          </div>
        </div>
      </Section>
      <Footer/>
    </MainLayout>
  )
}


export default Page
